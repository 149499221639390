@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: Raleway, sans-serif;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  padding: 0;
}

#soul-inspire-app {
  height: 100%;
  color: #fff;
  background-color: rgba(0,0,0,0.8);
}

.shadow-light {
  /* box-shadow: 0px 8px 16px 0px rgba(45,0,0,0.2); */
  box-shadow: 0px 8px 50px 10px rgba(244, 244, 245, 0.5);
}

h1 {
  font-size: 3em;
  font-weight: 200;
}

.display-flex {
  display: flex;
}

.flex-direction-column {
  flex-direction: column;
}